/**
 * @generated SignedSource<<2ef4771f57ec1daaa0663d127e079490>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Service = "Database" | "FraudulentResource" | "LimitedAccess" | "MassMedia" | "MobileApplication" | "Pilot" | "SharingPlatform" | "SocialAccount" | "SocialMedia";
export type State = "BlockPending" | "Blocked" | "Checked" | "Correcting" | "CorrectionDenied" | "DelegationRestored" | "Edited" | "InCommunication" | "InProgress" | "Irrelevant" | "Legitimate" | "Monitoring" | "New" | "NoContent" | "NoViolations" | "Settled" | "Unknown" | "Violation" | "WaitingForCustomer" | "WaitingForSupport";
export type TimeInterval = "Long" | "String";
import { FragmentRefs } from "relay-runtime";
export type SubscriptionsConnectionFragment$data = {
  readonly notificationSubscriptions: ReadonlyArray<{
    readonly emailPeriod: TimeInterval;
    readonly id: any;
    readonly isActive: boolean;
    readonly name: string;
    readonly query: {
      readonly actor: {
        readonly organization: {
          readonly exclude: ReadonlyArray<string> | null;
          readonly include: ReadonlyArray<string> | null;
        } | null;
      } | null;
      readonly entities: {
        readonly database: {
          readonly states: ReadonlyArray<State> | null;
        } | null;
        readonly fraudulentResource: {
          readonly states: ReadonlyArray<State> | null;
        } | null;
        readonly limitedAccess: {
          readonly states: ReadonlyArray<State> | null;
        } | null;
        readonly massMedia: {
          readonly states: ReadonlyArray<State> | null;
        } | null;
        readonly mobileApplication: {
          readonly states: ReadonlyArray<State> | null;
        } | null;
        readonly sharingPlatform: {
          readonly states: ReadonlyArray<State> | null;
        } | null;
        readonly socialAccount: {
          readonly states: ReadonlyArray<State> | null;
        } | null;
        readonly socialMedia: {
          readonly states: ReadonlyArray<State> | null;
        } | null;
      } | null;
      readonly entitiesAll: {
        readonly services: ReadonlyArray<Service> | null;
      } | null;
      readonly events: {
        readonly clientRequest: {
          readonly include: boolean | null;
        } | null;
        readonly commentAdded: {
          readonly include: boolean | null;
        } | null;
        readonly created: {
          readonly include: boolean | null;
        } | null;
        readonly leakCollectionAdded: {
          readonly include: boolean | null;
        } | null;
        readonly stateChanged: {
          readonly from: ReadonlyArray<State> | null;
          readonly to: ReadonlyArray<State> | null;
        } | null;
      } | null;
    };
  }> | null;
  readonly " $fragmentType": "SubscriptionsConnectionFragment";
};
export type SubscriptionsConnectionFragment$key = {
  readonly " $data"?: SubscriptionsConnectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubscriptionsConnectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "include",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "states",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "limit"
    },
    {
      "kind": "RootArgument",
      "name": "nameRegex"
    },
    {
      "kind": "RootArgument",
      "name": "subscriberLogin"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubscriptionsConnectionFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "limit",
          "variableName": "limit"
        },
        {
          "kind": "Variable",
          "name": "nameRegex",
          "variableName": "nameRegex"
        },
        {
          "kind": "Variable",
          "name": "subscriberLogin",
          "variableName": "subscriberLogin"
        }
      ],
      "concreteType": "NotificationSubscription",
      "kind": "LinkedField",
      "name": "notificationSubscriptions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isActive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "format",
              "value": "Human"
            }
          ],
          "kind": "ScalarField",
          "name": "emailPeriod",
          "storageKey": "emailPeriod(format:\"Human\")"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NotificationSubscriptionQuery",
          "kind": "LinkedField",
          "name": "query",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserFilter",
              "kind": "LinkedField",
              "name": "actor",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StringIncludeExcludeFilter",
                  "kind": "LinkedField",
                  "name": "organization",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "exclude",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AllEventsSelector",
              "kind": "LinkedField",
              "name": "events",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CreatedHistoryEventFilter",
                  "kind": "LinkedField",
                  "name": "created",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StateChangedHistoryEventFilter",
                  "kind": "LinkedField",
                  "name": "stateChanged",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "from",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "to",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CommentAddedHistoryEventFilter",
                  "kind": "LinkedField",
                  "name": "commentAdded",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LeakCollectionAddedHistoryEventFilter",
                  "kind": "LinkedField",
                  "name": "leakCollectionAdded",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ClientRequestHistoryEventFilter",
                  "kind": "LinkedField",
                  "name": "clientRequest",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ServiceEntityFilter",
              "kind": "LinkedField",
              "name": "entitiesAll",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "services",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EntitiesByServiceFilter",
              "kind": "LinkedField",
              "name": "entities",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MassMediaFilter",
                  "kind": "LinkedField",
                  "name": "massMedia",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SocialMediaFilter",
                  "kind": "LinkedField",
                  "name": "socialMedia",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MobileApplicationFilter",
                  "kind": "LinkedField",
                  "name": "mobileApplication",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SocialAccountFilter",
                  "kind": "LinkedField",
                  "name": "socialAccount",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FraudulentFilter",
                  "kind": "LinkedField",
                  "name": "fraudulentResource",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DatabaseFilter",
                  "kind": "LinkedField",
                  "name": "database",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LimitedAccessFilter",
                  "kind": "LinkedField",
                  "name": "limitedAccess",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SharingPlatformFilter",
                  "kind": "LinkedField",
                  "name": "sharingPlatform",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Queries",
  "abstractKey": null
};
})();

(node as any).hash = "8c79e5a7f2a758eead9186febbd469b5";

export default node;
