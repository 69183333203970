import { action, computed, observable, makeObservable } from 'mobx';

class SelectionStore {
  selectedMap: Map<string, any> = new Map();
  allCount: number = 0;
  isAllChecked: boolean = false;

  get selected(): any[] {
    return Array.from(this.selectedMap.values());
  }
  get selectedIds(): string[] {
    return Array.from(this.selectedMap.keys());
  }

  get selectedIdsWithChildren(): string[] {
    return this.selectedIds.flatMap(id => {
      const item = this.selectedMap.get(id);
      const childrenIds = item?.children?.map((child: any) => child.__id || child.id) || [];
      return [id, ...childrenIds];
    });
  }

  setAllChecked = async (): Promise<void> => {
    await new Promise(resolve => setTimeout(resolve, 900));
    this.setIsAllChecked(true);
  };

  setIsAllChecked(value: boolean): void {
    this.isAllChecked = value;
  }

  resetAllChecked = async (): Promise<void> => {
    await new Promise(resolve => setTimeout(resolve, 100));
    this.isAllChecked = false;
  };

  toggleSelected = (currentEntity: any): void => {
    if (currentEntity && currentEntity.id) {
      if (this.selectedMap.has(currentEntity.id))
        this.selectedMap.delete(currentEntity.id);
      else this.selectedMap.set(currentEntity.id, currentEntity);
      this.isAllChecked = false;
    }
  };

  addToSelection = (currentEntity: any): void => {
    if (currentEntity && currentEntity.id) {
      if (!this.selectedMap.has(currentEntity.id))
        this.selectedMap.set(currentEntity.id, currentEntity);
    }
  };

  updateSelectionByFilter = (newSelection: any): void => {
    const removedValues = this.selectedIds.filter(
      (item) => !newSelection.includes(item),
    );
    if (removedValues.length) {
      removedValues.forEach((i) => {
        this.selectedMap.delete(i);
      });
    }
  };

  updateSelection = (newSelection: any): void => {
    const newSelectionIds = newSelection.map((item: any) => item.id);
    const removedValues = this.selectedIds.filter(
      (item) => !newSelectionIds.includes(item),
    );
    newSelection.forEach((entity: any) => {
      if (entity && entity.id) {
        this.selectedMap.set(entity.id, entity);
      }
    });
    if (removedValues.length) {
      removedValues.forEach((entity) => {
        this.selectedMap.delete(entity);
      });
    }
  };

  setAllCount = (count: number): void => {
    this.allCount = count;
  };

  resetSelection = () => {
    this.selectedMap.clear();
  };

  constructor() {
    makeObservable(this, {
      selectedMap: observable,
      isAllChecked: observable,
      allCount: observable,
      selectedIds: computed,
      selected: computed,
      selectedIdsWithChildren: computed,
      toggleSelected: action,
      setAllChecked: action,
      resetAllChecked: action,
      addToSelection: action,
      resetSelection: action,
      setAllCount: action,
      updateSelection: action,
      updateSelectionByFilter: action,
      setIsAllChecked: action,
    });
  }
}

export default SelectionStore;
