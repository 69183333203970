import styled from 'styled-components';

import { Actions, IconButton, Node } from 'combinezone/core';

export const StyledIconButton = styled(IconButton)`
  & + div {
    left: 40px !important;
  }
`;

export const StyledNode = styled(Node)`
  pointer-events: none;
`;

export const StyledNodeMasonry = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  padding: 8px 16px;
  overflow: hidden;
`;

export const StyledActions = styled(Actions)`
  //position: unset;
`;

export const DropdownNode = styled(Node)`
  min-height: 48px;
`;

export const SavedFiltersModalOuter = styled.div`
  & + .combinezone-portal {
    & > div:first-child {
      z-index: 3 ;
    }
  }
`;
