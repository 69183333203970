import { FC, useMemo } from 'react';

import { DateTime } from 'luxon';

import EditNewsModal from '#Modals/EditNews';
import { useFragment } from 'react-relay/hooks';
import { Delete, Edit } from 'combinezone/icons';
import { useConfirm } from '#Components/Confirm';
import { useModals } from '#Components/ModalPortal';
import DeleteSelectedNews from '#Features/DeleteSelectedNews';
import { useAccount } from '#Providers/AccountProvider/context';
import { DATETIME_MED_WITH_ZEROES } from '#Utils/DateTimeFormat';
import { Caption, Flex, Heading, IconButton, Text } from 'combinezone/core';
import { NewsFeedItemFragment$key } from '#__artifacts/NewsFeedItemFragment.graphql';

import NewsSidebarRedirect from './NewsSidebar_Redirect';
import { newsFeedItemFragment } from '../NewsFeed/NewsFeed_Item';
import {
  ActionBarNews,
  InfoColumn,
  NewsSidebarHeader,
  NewsSidebarIn,
  NewsSidebarOut,
  NewsSidebarPreview,
  NewsSidebarPreviewOut,
  StyledTextLinkNews,
} from './NewsSidebar.styles';

const valueFormatter = (value: string): string => {
  const clearValue = value.trim();
  if (!clearValue.length) {
    return '-';
  }
  return clearValue;
};

const NewsSidebar: FC<{
  itemRef: NewsFeedItemFragment$key;
  nextSelectedItemId: string | null;
  setIsErrorNews: (isErrorNews: boolean) => void;
}> = ({ itemRef, nextSelectedItemId, setIsErrorNews }) => {
  const { isAdmin } = useAccount();
  const { openModal, closeModal } = useModals();

  const { showConfirm } = useConfirm();

  const newsItem = useFragment(newsFeedItemFragment, itemRef);

  const content = useMemo(() => {
    const contentRows = newsItem.content
      .split(/\n/)
      .map((contentLine: string) => {
        const splitOnIndex = contentLine.indexOf(':');
        const key = contentLine.slice(0, splitOnIndex);
        const value = contentLine.slice(splitOnIndex + 1).trim();

        return (
          <InfoColumn key={key.trim()}>
            <Text variant="secondary">{key.trim()}</Text>
            <Text>{valueFormatter(value)}</Text>
          </InfoColumn>
        );
      });
    return contentRows;
  }, [newsItem]);

  return (
    <NewsSidebarOut>
      <NewsSidebarHeader>
        <Text isClipped numOfLines={1} showTooltipIfClipped={false}>
          <Heading size="md">{newsItem.caption}</Heading>
        </Text>
        <ActionBarNews>
          {isAdmin ? (
            <>
              <IconButton
                icon={Edit}
                tooltip={null}
                testId="EditNews_IconButton"
                onClick={() =>
                  openModal(
                    <EditNewsModal model={newsItem} onClose={closeModal} />,
                  )
                }
              />
              <IconButton
                icon={Delete}
                tooltip={null}
                testId="DeleteNews_IconButton"
                onClick={() =>
                  showConfirm(
                    <DeleteSelectedNews
                      newsIds={[newsItem?.id]}
                      nextSelectedItemId={nextSelectedItemId}
                      setIsErrorNews={setIsErrorNews}
                    />,
                  )
                }
              />
            </>
          ) : null}
        </ActionBarNews>
      </NewsSidebarHeader>
      <NewsSidebarIn>
        <NewsSidebarPreviewOut>
          <NewsSidebarPreview src={newsItem?.imageLink ?? undefined} />
        </NewsSidebarPreviewOut>
        <Caption isParagraph>
          {DateTime.fromSeconds(Number(newsItem.created)).toLocaleString(
            DATETIME_MED_WITH_ZEROES,
          )}
        </Caption>
        <Heading size="md">{newsItem.caption.trim()}</Heading>
        <div style={{ padding: '16px 0px 0px 0px' }}>
          {content}
          {newsItem.source && (
            <InfoColumn>
              <Text variant="secondary">Ссылка на источник</Text>
              <StyledTextLinkNews
                to={newsItem.source}
                isExternal
                testId={`LinkTo_${newsItem.source}`}
              >
                {newsItem.source}
              </StyledTextLinkNews>
            </InfoColumn>
          )}
          {newsItem.leakCollections.length > 0 && (
            <InfoColumn style={{ marginBottom: '0px' }}>
              <Text variant="secondary">База данных</Text>
              <Flex gap="4px" alignItems="center">
                <Text>{newsItem.leakCollections[0]}</Text>
                <NewsSidebarRedirect
                  collectionName={newsItem.leakCollections[0]}
                />
              </Flex>
            </InfoColumn>
          )}
        </div>
      </NewsSidebarIn>
    </NewsSidebarOut>
  );
};

export default NewsSidebar;
NewsSidebar.displayName = 'NewsSidebar';
