/**
 * @generated SignedSource<<56617e22035c4cf0e84e50966e90cdcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Role = "Admin" | "Client";
export type UsersQuery$variables = {
  hasEmail?: boolean | null;
  isBlocked?: boolean | null;
  logins?: ReadonlyArray<string> | null;
  organizations?: ReadonlyArray<string> | null;
  substring?: string | null;
};
export type UsersQuery$data = {
  readonly users: ReadonlyArray<{
    readonly email: string | null;
    readonly firstName: string | null;
    readonly isBlocked: boolean;
    readonly lastName: string | null;
    readonly login: string;
    readonly middleName: string | null;
    readonly organization: {
      readonly id: any;
      readonly name: string;
    } | null;
    readonly role: Role | null;
  }> | null;
};
export type UsersQuery = {
  response: UsersQuery$data;
  variables: UsersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasEmail"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isBlocked"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "logins"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizations"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "substring"
},
v5 = [
  {
    "kind": "Variable",
    "name": "hasEmail",
    "variableName": "hasEmail"
  },
  {
    "kind": "Variable",
    "name": "isBlocked",
    "variableName": "isBlocked"
  },
  {
    "kind": "Variable",
    "name": "logins",
    "variableName": "logins"
  },
  {
    "kind": "Variable",
    "name": "organizations",
    "variableName": "organizations"
  },
  {
    "kind": "Variable",
    "name": "substring",
    "variableName": "substring"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isBlocked",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "login",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "middleName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UsersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UsersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a4a54917d06ab06dc18e90bb3dabc745",
    "id": null,
    "metadata": {},
    "name": "UsersQuery",
    "operationKind": "query",
    "text": "query UsersQuery(\n  $logins: [String!]\n  $substring: String\n  $organizations: [String!]\n  $isBlocked: Boolean\n  $hasEmail: Boolean\n) {\n  users(logins: $logins, substring: $substring, organizations: $organizations, isBlocked: $isBlocked, hasEmail: $hasEmail) {\n    __typename\n    email\n    firstName\n    isBlocked\n    lastName\n    login\n    middleName\n    organization {\n      id\n      name\n    }\n    role\n  }\n}\n"
  }
};
})();

(node as any).hash = "017a5eabf6c78de783c11ad0ee4ed1f7";

export default node;
