/**
 * @generated SignedSource<<8775848e7616cead02251803c483fdc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type EntitySource = "Bizone" | "BizoneAndClient" | "Client";
export type Service = "Database" | "FraudulentResource" | "LimitedAccess" | "MassMedia" | "MobileApplication" | "Pilot" | "SharingPlatform" | "SocialAccount" | "SocialMedia";
export type State = "BlockPending" | "Blocked" | "Checked" | "Correcting" | "CorrectionDenied" | "DelegationRestored" | "Edited" | "InCommunication" | "InProgress" | "Irrelevant" | "Legitimate" | "Monitoring" | "New" | "NoContent" | "NoViolations" | "Settled" | "Unknown" | "Violation" | "WaitingForCustomer" | "WaitingForSupport";
export type SubService = "BankCard" | "Fraud" | "IpRange" | "None" | "Phishing" | "RelatedUrl" | "Suspicious" | "TaxpayerId";
export type Tonality = "Negative" | "Neutral" | "Positive" | "Unset";
export type BlocksSubscription$variables = {
  exactPeriod: boolean;
  from: any;
  organization?: string | null;
  to: any;
};
export type BlocksSubscription$data = {
  readonly calculateDashboard: {
    readonly byCollectionCounts: ReadonlyArray<{
      readonly collectionName: string;
      readonly count: number;
    }>;
    readonly entityType: "DatabaseCollectionCounters";
  } | {
    readonly byZoneCounts: ReadonlyArray<{
      readonly count: number;
      readonly domainZone: string;
    }>;
    readonly entityType: "FraudDomainZoneCounters";
  } | {
    readonly counts: ReadonlyArray<{
      readonly count: number;
      readonly source: EntitySource;
      readonly sourceName: string;
      readonly state: State;
    }>;
    readonly entityType: "SocialAccountCounters";
  } | {
    readonly counts: ReadonlyArray<{
      readonly count: number;
      readonly service: Service;
      readonly sourceName: string;
    }>;
    readonly entityType: "LeakCounters";
  } | {
    readonly counts: ReadonlyArray<{
      readonly count: number;
      readonly service: Service;
      readonly tonality: Tonality;
    }>;
    readonly entityType: "MediaCounters";
  } | {
    readonly counts: ReadonlyArray<{
      readonly count: number;
      readonly source: EntitySource;
      readonly state: State;
    }>;
    readonly entityType: "MobileApplicationCounters";
    readonly malwareCounts: ReadonlyArray<{
      readonly count: number;
      readonly source: EntitySource;
    }>;
  } | {
    readonly domainCounts: ReadonlyArray<{
      readonly count: number;
      readonly source: EntitySource;
    }>;
    readonly domainCountsMonthly: ReadonlyArray<{
      readonly count: number;
      readonly month: string;
      readonly source: EntitySource;
    }>;
    readonly entityType: "FraudBlockCounters";
  } | {
    readonly domainCounts: ReadonlyArray<{
      readonly count: number;
      readonly source: EntitySource;
      readonly state: State;
      readonly subService: SubService;
    }>;
    readonly entityType: "FraudulentResourceCounters";
    readonly urlCounts: ReadonlyArray<{
      readonly count: number;
      readonly source: EntitySource;
      readonly subService: SubService;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly entityType: "%other";
  } | {
    readonly entityType: "FraudBlockTimeStatistics";
    readonly overall: {
      readonly maxHours: number;
      readonly minHours: number;
      readonly percentile80Hours: number;
    } | null;
    readonly overallMonthly: ReadonlyArray<{
      readonly maxHours: number;
      readonly minHours: number;
      readonly month: string;
      readonly percentile80Hours: number;
    }>;
    readonly requestsOnly: {
      readonly maxHours: number;
      readonly minHours: number;
      readonly percentile80Hours: number;
    } | null;
    readonly requestsOnlyMonthly: ReadonlyArray<{
      readonly maxHours: number;
      readonly minHours: number;
      readonly month: string;
      readonly percentile80Hours: number;
    }>;
  } | null;
};
export type BlocksSubscription = {
  response: BlocksSubscription$data;
  variables: BlocksSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "exactPeriod"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "from"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organization"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "to"
},
v4 = [
  {
    "kind": "Variable",
    "name": "exactPeriod",
    "variableName": "exactPeriod"
  },
  {
    "kind": "Variable",
    "name": "from",
    "variableName": "from"
  },
  {
    "kind": "Variable",
    "name": "organization",
    "variableName": "organization"
  },
  {
    "kind": "Variable",
    "name": "to",
    "variableName": "to"
  }
],
v5 = {
  "alias": "entityType",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subService",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FraudulentDomainCount",
      "kind": "LinkedField",
      "name": "domainCounts",
      "plural": true,
      "selections": [
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FraudulentUrlCount",
      "kind": "LinkedField",
      "name": "urlCounts",
      "plural": true,
      "selections": [
        (v6/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "FraudulentResourceCounters",
  "abstractKey": null
},
v11 = [
  (v8/*: any*/),
  (v9/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v13 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SourceCount",
      "kind": "LinkedField",
      "name": "domainCounts",
      "plural": true,
      "selections": (v11/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MonthSourceCount",
      "kind": "LinkedField",
      "name": "domainCountsMonthly",
      "plural": true,
      "selections": [
        (v12/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "FraudBlockCounters",
  "abstractKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minHours",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxHours",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percentile80Hours",
  "storageKey": null
},
v17 = [
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/)
],
v18 = [
  (v12/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/)
],
v19 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BlockTimeStatistics",
      "kind": "LinkedField",
      "name": "overall",
      "plural": false,
      "selections": (v17/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BlockTimeMonthlyStatistics",
      "kind": "LinkedField",
      "name": "overallMonthly",
      "plural": true,
      "selections": (v18/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BlockTimeStatistics",
      "kind": "LinkedField",
      "name": "requestsOnly",
      "plural": false,
      "selections": (v17/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BlockTimeMonthlyStatistics",
      "kind": "LinkedField",
      "name": "requestsOnlyMonthly",
      "plural": true,
      "selections": (v18/*: any*/),
      "storageKey": null
    }
  ],
  "type": "FraudBlockTimeStatistics",
  "abstractKey": null
},
v20 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DomainZoneCount",
      "kind": "LinkedField",
      "name": "byZoneCounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "domainZone",
          "storageKey": null
        },
        (v9/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "FraudDomainZoneCounters",
  "abstractKey": null
},
v21 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StateSourceCount",
      "kind": "LinkedField",
      "name": "counts",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SourceCount",
      "kind": "LinkedField",
      "name": "malwareCounts",
      "plural": true,
      "selections": (v11/*: any*/),
      "storageKey": null
    }
  ],
  "type": "MobileApplicationCounters",
  "abstractKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceName",
  "storageKey": null
},
v23 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StateSourceSourceNameCount",
      "kind": "LinkedField",
      "name": "counts",
      "plural": true,
      "selections": [
        (v8/*: any*/),
        (v22/*: any*/),
        (v7/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "SocialAccountCounters",
  "abstractKey": null
},
v24 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DataLeakCollectionCount",
      "kind": "LinkedField",
      "name": "byCollectionCounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "collectionName",
          "storageKey": null
        },
        (v9/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "DatabaseCollectionCounters",
  "abstractKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "service",
  "storageKey": null
},
v26 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ServiceSourceNameCount",
      "kind": "LinkedField",
      "name": "counts",
      "plural": true,
      "selections": [
        (v25/*: any*/),
        (v22/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "LeakCounters",
  "abstractKey": null
},
v27 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ServiceTonalityCount",
      "kind": "LinkedField",
      "name": "counts",
      "plural": true,
      "selections": [
        (v25/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tonality",
          "storageKey": null
        },
        (v9/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "MediaCounters",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BlocksSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "calculateDashboard",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v10/*: any*/),
          (v13/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscriptions",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BlocksSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "calculateDashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v5/*: any*/),
          (v10/*: any*/),
          (v13/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4807830c75809fb67f7f10f4b9334873",
    "id": null,
    "metadata": {},
    "name": "BlocksSubscription",
    "operationKind": "subscription",
    "text": "subscription BlocksSubscription(\n  $organization: String\n  $from: Long!\n  $to: Long!\n  $exactPeriod: Boolean!\n) {\n  calculateDashboard(organization: $organization, from: $from, to: $to, exactPeriod: $exactPeriod) {\n    __typename\n    entityType: __typename\n    ... on FraudulentResourceCounters {\n      domainCounts {\n        subService\n        state\n        source\n        count\n      }\n      urlCounts {\n        subService\n        source\n        count\n      }\n    }\n    ... on FraudBlockCounters {\n      domainCounts {\n        source\n        count\n      }\n      domainCountsMonthly {\n        month\n        source\n        count\n      }\n    }\n    ... on FraudBlockTimeStatistics {\n      overall {\n        minHours\n        maxHours\n        percentile80Hours\n      }\n      overallMonthly {\n        month\n        minHours\n        maxHours\n        percentile80Hours\n      }\n      requestsOnly {\n        minHours\n        maxHours\n        percentile80Hours\n      }\n      requestsOnlyMonthly {\n        month\n        minHours\n        maxHours\n        percentile80Hours\n      }\n    }\n    ... on FraudDomainZoneCounters {\n      byZoneCounts {\n        domainZone\n        count\n      }\n    }\n    ... on MobileApplicationCounters {\n      counts {\n        state\n        source\n        count\n      }\n      malwareCounts {\n        source\n        count\n      }\n    }\n    ... on SocialAccountCounters {\n      counts {\n        source\n        sourceName\n        state\n        count\n      }\n    }\n    ... on DatabaseCollectionCounters {\n      byCollectionCounts {\n        collectionName\n        count\n      }\n    }\n    ... on LeakCounters {\n      counts {\n        service\n        sourceName\n        count\n      }\n    }\n    ... on MediaCounters {\n      counts {\n        service\n        tonality\n        count\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8882de6c1d55b673bc8a4867196ab7d0";

export default node;
