import styled from 'styled-components';

import { Badge, BadgeText, IconButton } from 'combinezone/core';

export const BadgesMasonryOut = styled.div`
  z-index: 0;
  position: relative;
  display: flex;
  width: 100%;
  height: 24px;
`;

const BadgesMasonryBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  width: 100%;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
`;

export const BadgesMasonryVisibleBlock = styled.div<{
  $lastVisibleIndex: number;
}>`
    width: fit-content;
    height: 24px;
    overflow: hidden;
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    z-index: 0;
    
  &
    > div:not(.counter):nth-child(n
      + ${({ $lastVisibleIndex }) => $lastVisibleIndex + 1}) {
    display: none;
  }
`;

export const BadgesMasonryInvisibleBlock = styled(BadgesMasonryBlock)`
  z-index: -1;

  & > div {
    visibility: hidden;
  }
`;

export const StyledBadge = styled(Badge)`
  &:hover {
    cursor: pointer;
    border: 1px solid #209bcf;
  }

  & ${BadgeText} {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  display: inline-grid;
`;

export const StyledIconButton = styled(IconButton)<{
  $left: number;
}>`
    left: ${({ $left }) => $left + 'px'};
    position: absolute;
    top: -8px;
`;
