import { graphql } from 'babel-plugin-relay/macro';

const UsersQuery = graphql`
  query UsersQuery(
    $logins: [String!]
    $substring: String
    $organizations: [String!]
    $isBlocked: Boolean
    $hasEmail: Boolean
  ) {
    users(
      logins: $logins
      substring: $substring
      organizations: $organizations
      isBlocked: $isBlocked
      hasEmail: $hasEmail
    ) {
      email
      firstName
      isBlocked
      lastName
      login
      middleName
      organization {
        id
        name
      }
      role
    }
  }
`;

export default UsersQuery;
