// @ts-nocheck
import { FC, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { graphql } from 'babel-plugin-relay/macro';
import ErrorNews from '#Components/ErrorStub/ErrorNews';
import { usePaginationFragment } from 'react-relay/hooks';
import { NewsLayoutQuery$data } from '#__artifacts/NewsLayoutQuery.graphql';
import { MainPageNewsFeedFragment$key } from '#__artifacts/MainPageNewsFeedFragment.graphql';
import { MainPageNewsFeedPaginationQuery } from '#__artifacts/MainPageNewsFeedPaginationQuery.graphql';

import { MainPageNewsOut } from './Main.styles';
import NewsSidebar from '../../Components/NewsSidebar';
import NewsFeed from '../../Components/NewsFeed/NewsFeed';
import NewsFeedStub from '../../Components/NewsFeed/NewsFeed_Stub';

const newsFeedFragment = graphql`
  fragment MainPageNewsFeedFragment on Queries
  @refetchable(queryName: "MainPageNewsFeedPaginationQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 50 }
    cursor: { type: "String" }
  ) {
    news(after: $cursor, first: $count)
      @connection(key: "MainPageNewsFeedFragment__news") {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          ...NewsFeedItemFragment
        }
      }
    }
  }
`;

const NewsMainPage: FC<{ queryRef: NewsLayoutQuery$data }> = ({ queryRef }) => {
  const { data, isLoadingNext, hasNext, loadNext } = usePaginationFragment<
    MainPageNewsFeedPaginationQuery,
    MainPageNewsFeedFragment$key
  >(newsFeedFragment, queryRef);

  const [isErrorNews, setIsErrorNews] = useState(false);

  const items = useMemo(
    () =>
      (data.news?.edges ?? [])
        .filter((item) => item && item.node !== null)
        .map(({ node }) => node),
    [data],
  );

  const { newsId } = useParams();

  const selectedItemRef = useMemo(() => {
    if (!items.length) {
      return null;
    }
    if (Boolean(newsId)) {
      return items.find(({ id }) => newsId === id) ?? queryRef.selected;
    }
    return items[0];
  }, [queryRef.selected, items, newsId]);

  const nextSelectedItemId = useMemo(() => {
    const selectedItemId = selectedItemRef?.id;
    const foundIndex = items.findIndex((item) => item.id === selectedItemId);

    if (foundIndex !== -1) {
      const nextIndex = foundIndex + 1;
      const previousIndex = foundIndex - 1;

      if (nextIndex < items.length) {
        return items[nextIndex].id;
      } else if (previousIndex >= 0) {
        return items[previousIndex].id;
      }
    }
    return null;
  }, [items, selectedItemRef?.id]);

  if (items.length === 0) {
    return <NewsFeedStub />;
  }

  return (
    <>
      {isErrorNews ? (
        <ErrorNews setIsErrorNews={setIsErrorNews} newsIds={newsId} />
      ) : (
        <MainPageNewsOut>
          <NewsFeed
            items={items}
            isLoadingNext={isLoadingNext}
            hasNext={hasNext}
            loadNext={loadNext}
          />
          {selectedItemRef && (
            <NewsSidebar
              itemRef={selectedItemRef}
              nextSelectedItemId={nextSelectedItemId}
              setIsErrorNews={setIsErrorNews}
            />
          )}
        </MainPageNewsOut>
      )}
    </>
  );
};

export default NewsMainPage;
NewsMainPage.displayName = 'NewsMainPage';
